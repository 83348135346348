export enum UiState {
    NONE = "none",
    LANDING = "landingpage",
    HINT = "hintpage",
    TUTORIAL = "tutorialpage",
    CALL = "callpage",
    MASK = "maskpage",
    CANCELCALL = "cancelcallpage",
    CHAT = "chatpage",
    ENDOFCALL = "endofcallpage",
    LOADING = "loadingpage",
    AGE = "agepage",
    END_ONE = "endOne",
    END_TWO = "endTwo",
    POLICY = "privacypolicy",
    IMPRINT = "imprint",
    OVER50 = "over50",
}

export enum WebUiState {
    NONE = "none",
    LANDING = "landing",
    HINT = "hint",
    TUTORIAL = "tutorial",
    MASK = "mask",
    CANCELCALL = "cancelCall",
    CHAT = "chatBot",
    ENDOFCALL = "endOfCall",
    LOADING = "loading",
    AGE = "age",
}

export enum ChatbotUiState {
    NONE = "none",
    LANDING = "landing",
    CHATBOT = "chatbot",
    AGE = "age",
    HINT = "hint",
    ENDOFCALL = "endOfCall",
    CANCELCALL = "cancelCall",
    TUTORIAL = "tutorial",
    MASK = "mask",
    OVER50 = "over50",
    LOADING = "loading",
    POLICY = "privacypolicy",
    IMPRINT = "imprint",
}

export enum ResetTrigger {
    NONE = "",
    ENDOFCALLTIMER = "endOfCallTimer",
    CHATINACTIVETIMER = "chatInactiveTimer",
    GENERALINACTIVETIMER = "generalInactiveTimer",
    CANCELCALLCHAT = "cancelCallChat",
    RELOADCALLCHAT = "reloadCallChat",
    CANCELCALLOVER50 = "cancelCallOver50",
    RELOADCALLOVER50 = "reloadCallOver50",
    CANCELLOADING = "cancelLoadingManually",
}
